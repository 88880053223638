<template>
  <el-form
    :rules="rules"
    ref="formRef"
    :model="fromData"
    class="shop-improve-from reg-form"
    label-position="top"
  >
  <el-form-item
      prop="phone"
    >
      <el-input
        v-model="fromData.phone"
        type="text"
        maxlength="20"
        class="form-item-input no-number phone"
        placeholder="请输入您的账号"
        v-elInput
      />
    </el-form-item>
    <el-form-item
      prop="pwd"
    >
      <el-input
          v-model="fromData.pwd"
          :type='fromData.pwdType'
          class="form-item-input pwd"
          placeholder="请输入您的密码"
          onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
          maxlength="30"
          v-elInput
      >
        <template #suffix>
          <el-icon class="el-input__icon cur_p" style="margin-right: 20px;font-size: 20px" @click.stop='changePwdType'>
            <component :is='fromData.viewPwd'/>
          </el-icon>
        </template>
      </el-input>
    </el-form-item>
    <div class="shop-konw">
      <el-checkbox v-model="isRead" size="large">
        <span>我已阅读并同意</span>
        <a class="book" target="_blank"
           href="https://oss.musaemotion.com//WEB/assets/html/privacyPolicy.html">《隐私协议》</a>
        <a class="book" target="_blank"
           href="https://oss.musaemotion.com//WEB/assets/html/termsService.html">《服务协议》</a>
      </el-checkbox>
      </div>
    <el-form-item>
      <button type="button" @click='submitForm(formRef)' :disabled="canClick" class="wy-button next-btn login">登录
      </button>
    </el-form-item>
  </el-form>
</template>

<script  setup>
import {computed, getCurrentInstance, reactive, ref, watch} from 'vue';
import {useRouter} from "vue-router";
import {loginIn} from '@utils/api/user.js'
import {encrypt} from "@utils/tool.js";
import {formRules} from '../../config'
import {setToken} from "@utils/tool/token";
import {Hide, View} from "@element-plus/icons-vue";

const router = useRouter()
const {proxy} = getCurrentInstance();
const formRef = ref(null)
const fromData = reactive({
  //电话
  phone: '',
  //密码
  pwd: '',
  pwdType: 'password',
  viewPwd: Hide
})
const rules = reactive({
  phone: formRules.account,
  pwd: formRules.pwd
});
const changePwdType = () => {
  fromData.pwdType = fromData.pwdType === 'password' ? 'text' : 'password'
  fromData.viewPwd = fromData.pwdType === 'password' ? Hide : View
}
const isRead = ref(false)
const canClick = computed(() => {
  return !fromData.phone || !fromData.pwd
})
const submitForm = (formEl) => {
  if (!isRead.value) {
    proxy.$message.error('请先阅读并同意《隐私协议》、《服务协议》')
    return
  }
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const loginInfo = {
        account: fromData.phone,
        password: encrypt(fromData.pwd)
      }
      loginIn(loginInfo).then(res => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg)
          return
        }
        proxy.$message.success('登录成功')
        proxy.$cookies.set('userInfo', res.data, 60 * 60 * 24 * 7)
        setToken(res.data.token, 1)
        if (sessionStorage.getItem('WYLG_preLoginRoute')) {
          router.push({path: sessionStorage.getItem('WYLG_preLoginRoute')})
          sessionStorage.removeItem('WYLG_preLoginRoute')
        } else {
          router.push({path: '/'})
        }
      })
    } else {
      return false
    }
  })
}
watch(() => proxy.$parent.tabChecked, (newVal) => {
  let refs = formRef.value
  if (!refs) return
  refs.clearValidate()
})
</script>

<style lang="scss" scoped>
  @import url(../../register/common.scss);
  .shop-improve-from{
    .el-form-item{
      margin-top: 36px;
    }
    .next-btn{
      margin-top: 66px;
      width: 100%;
      height: 80px;
      font-size: 18px;
    }
  }
</style>