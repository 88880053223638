<template>
  <!--  商城登录页面-->
  <div class="shop-register ">
    <ShopLogo type="index" class="logo shop-logo"></ShopLogo>
    <div class="shop-middle-content" >
      <div class="shop-welcome">WELCOME</div>
      <div class="login-tab">
        <div class="item " :class="tabChecked === 0 ?'active':''" @click="tabCheck(0)">密码登录</div>
        <div class="item" :class="tabChecked === 1 ?'active':''" @click="tabCheck(1)">验证码登录</div>
      </div>
      <pwd v-if="tabChecked === 0" class="animate__animated animate__fadeIn"></pwd>
      <phone v-if="tabChecked === 1" class="animate__animated animate__fadeIn"></phone>
      <div class="foot-text">
        <span class="book cur_p" @click="goReg">注册账户</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import Pwd from './components/pwd.vue';
import Phone from './components/phone.vue';
import ShopLogo from "@views/shop/components/logo.vue";

const tabChecked = ref(0);
const {proxy} = getCurrentInstance();
const tabCheck = (e)=>{
  if(e !== tabChecked.value) tabChecked.value = e;
}
const goReg = () => {
  proxy.$router.push('/register')
}
defineExpose({
  tabChecked
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopLogin",
});
</script>

<style lang="scss" src="./index.scss" scoped>
</style>
<style lang="scss" src="../register/common.scss" scoped>
</style>
