
<template>
  <el-form
    :rules="rules"
    ref="formRef"
    :model="fromData"
    class="shop-improve-from reg-form"
    label-position="top"
  >
  <el-form-item
      prop="phone"
    >
      <el-input
        v-model="fromData.phone"
        type="number"
        class="form-item-input no-number phone"
        placeholder="请输入您的手机号"
        maxlength="11"
        v-elInput
      />
    </el-form-item>
    <el-form-item
      prop="pwd"
    >
    <div class="form-item">
      <el-input
        v-model="fromData.code"
        type="number"
        maxlength="6"
        class="form-item-input no-number code"
        placeholder="请输入验证码"
        v-elInput
      />
      <div class="code-btn" :class="isOK?'':'disabled'" @click.stop='handleClickGetCode'>{{ str }}</div>
    </div>
    </el-form-item>
    <div class="shop-konw">
        <el-checkbox v-model="isRead"  size="large" >
          <span>我已阅读并同意</span>
          <a class="book" target="_blank"
             href="https://oss.musaemotion.com//WEB/assets/html/privacyPolicy.html">《隐私协议》</a>
          <a class="book" target="_blank"
             href="https://oss.musaemotion.com//WEB/assets/html/termsService.html">《服务协议》</a>
        </el-checkbox>
      </div>
    <el-form-item>
      <button type="button" @click='submitForm(formRef)' :disabled="canClick" class="wy-button next-btn login">登录
      </button>
    </el-form-item>
  </el-form>
</template>

<script  setup>
import {computed, getCurrentInstance, reactive, ref, watch} from 'vue';
import {useRouter} from "vue-router";
import {codeBtn} from "@utils/tool/getCode";
import {codeSend} from "@utils/api/person";
import {formRules} from '../../config';
import {loginInCode} from '@utils/api/user';
import {setToken} from "@utils/tool/token.js";
import {setBaseUserInfo} from "@utils/tool/getUserInfo";

const router = useRouter()
const { str , getCode , isOK } = codeBtn()

const isRead = ref(false)
const {proxy} = getCurrentInstance();
const formRef = ref(null)
const fromData = reactive({
  //电话
  phone: '',
  //密码
  code: '',
})
const rules = reactive({
  phone: formRules.phone,
  code: formRules.code
});
const canClick = computed(() => {
  return !fromData.phone || !fromData.code
})
const submitForm = (formEl) => {
  if (!isRead.value) return proxy.$message.error('请先阅读并同意《隐私协议》、《服务协议》')
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const loginInfo = {
        mobile: fromData.phone,
        vcode: fromData.code
      }
      loginInCode(loginInfo).then(res => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg)
          return
        }
        proxy.$message.success('登录成功')
        proxy.$cookies.set('userInfo', res.data, 60 * 60 * 24 * 7)
        setToken(res.data.token, 1)
        setBaseUserInfo()
        if (sessionStorage.getItem('WYLG_preLoginRoute')) {
          router.push({path: sessionStorage.getItem('WYLG_preLoginRoute')})
          sessionStorage.removeItem('WYLG_preLoginRoute')
        } else {
          router.push({path: '/'})
        }
      })
    } else {
      return false
    }
  })
}
const handleClickGetCode = ()=>{
  if (!isOK.value) return
  formRef.value.validateField('phone', (errorMsg) => {
    if (!errorMsg) return
    codeSend({phone: fromData.phone, placeId: 4}).then((res) => {
      if (res.code !== 0) return proxy.$message.error(res.msg)
      proxy.$message.success('验证码发送成功')
      getCode()
    })
  })
}
watch(() => proxy.$parent.tabChecked, (newVal) => {
  let refs = formRef.value
  if (!refs) return
  refs.clearValidate()
})
</script>

<style lang="scss" scoped>
  @import url(../../register/common.scss);
  .shop-improve-from{
    .el-form-item{
      margin-top: 36px;
    }
    .next-btn{
      margin-top: 66px;
      width: 100%;
      height: 80px;
      font-size: 18px;
    }
    .form-item{
      width: 100%;
      position: relative;
      .code-btn{
      color: #466AFE;
      height: 80px;
      line-height: 80px;
      position: absolute;
      right: 24px;
      font-size: 18px;
      top: 0;
      cursor: pointer;
        user-select: none;
      &.disabled{
        color: #697691;
        cursor: not-allowed;
      }
    }
    }
  }
</style>